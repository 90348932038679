<template>
  <el-dialog class="select-city-container" :show-close="false" :visible="showState" width="800px" top="10vh"
    @close="hidePopups()">
    <div class="flex select-block">
      <div class="select-block-l">
        <div class="filter-input">
          <el-input placeholder="搜索城市名称" suffix-icon="el-icon-search" v-model="filterText"></el-input>
        </div>
        <el-tree class="city-tree" ref="cityTree" :props="props" node-key="id" :data="treeData" show-checkbox
          :filter-node-method="filterNode" @check="handleCheckChange"></el-tree>
      </div>
      <ul class="select-block-r">
        <li class="flex r-prompt">
          <span class="r-prompt-checked">已选 {{ treeSelectList.length }} 个</span>
          <span class="r-prompt-clear" @click="handleClear()">清空</span>
        </li>
        <li class="flex r-item" v-for="(item, i) in treeSelectList" :key="i">
          <span>{{ item.label }}</span>
          <i class="el-icon-close r-item-clear" @click="handleDeleteCity(item, i)"></i>
        </li>
      </ul>
    </div>
    <div slot="footer">
      <el-button @click="hidePopups">取 消</el-button>
      <el-button type="primary" @click="handelSave">保 存</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  model: {
    prop: 'treeSelectList',
    event: 'selectValue',
  },
  props: {
    showState: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filterText: '',
      props: {
        id: 'id',
        children: 'children',
        label: 'label',
      },
      treeData: [
        {
          id: 1,
          label: '重庆市',
          children: [
            { id: 4, label: '重庆' },
            { id: 7, label: '重庆南岸' },
          ],
        },
        {
          id: 2,
          label: '北京市',
          children: [{ id: 5, label: '北京市' }],
        },
        {
          id: 3,
          label: '上海市',
          children: [{ id: 6, label: '上海市' }],
        },
        {
          id: 8,
          label: '陕西省',
          children: [{ id: 9, label: '西安市' }],
        },
      ],
      treeSelectList: [],
    };
  },
  watch: {
    showState(val) {
      if (val) {
      }
    },
    filterText(val) {
      this.$refs.cityTree.filter(val);
    },
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    handleCheckChange(checkedNodes, checkedKeys) {
      const nodes = this.$refs.cityTree.getCheckedNodes();
      this.treeSelectList = nodes.filter((item) => !item[this.props.children]);
    },
    handleClear() {
      this.treeSelectList = [];
      this.$refs.cityTree.setCheckedNodes([]);
    },
    handleDeleteCity(item, index) {
      this.treeSelectList.splice(index, 1);
      this.$refs.cityTree.setCheckedNodes(this.treeSelectList);
    },
    handelSave() {
      this.$emit('selectValue', this.treeSelectList);
      this.hidePopups();
    },
    hidePopups() {
      this.$emit('hidePopups');
    },
  },
};
</script>

<style lang="scss" scoped>
.select-city-container {
  ::v-deep .el-dialog__body {
    padding: 0;
  }

  ::v-deep .el-dialog__header {
    padding: 0;
    border: none;
  }

  ::v-deep .el-dialog__footer {
    padding: 16px;
    border-top: 1px solid #eee;
    text-align: center;
  }

  .select-block {
    min-height: 500px;

    .select-block-l {
      flex: 1;
      border-right: 1px solid #eee;

      .filter-input {
        padding: 16px;
        border-bottom: 1px solid #eee;
      }

      .city-tree {
        margin: 16px;
      }
    }

    .select-block-r {
      flex: 1;
      padding: 0 16px;

      .r-prompt {
        align-items: center;
        justify-content: space-between;
        margin: 24px 0;

        .r-prompt-checked {
          font-size: 14px;
          font-weight: bold;
        }

        .r-prompt-clear:hover {
          cursor: pointer;
          color: #2861e2;
          font-weight: bold;
        }
      }

      .r-item {
        align-items: center;
        justify-content: space-between;
        padding: 8px 16px;
        border: 1px solid #d7d1d1;
        margin-bottom: 8px;
        border-radius: 4px;

        .r-item-clear:hover {
          cursor: pointer;
          color: #2861e2;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
