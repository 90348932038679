<template>
  <div class="overall-situation-container" v-loading="isboxLoading">
    <ul class="flex operate-container">
      <li 
        class="operate-item" 
        v-for="(item, i) in timeList" 
        :class="i === timeIndex ? 'operate-item-checked' : ''" 
        :key="i" 
        @click="handleTime(item, i)">
        {{ item }}
      </li>
      <el-date-picker
        class="time-limit"
        v-model="dateTime"
        type="datetimerange"
        value-format="yyyy-MM-dd HH:mm:ss"
        :default-time="['00:00:00', '23:59:59']"
        range-separator="至"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        @change="mirDate"
        v-show="isTimeLimit"
      ></el-date-picker>
    </ul>
    <div class="search-form-container">
      <el-form ref="searchForm" :model="chartQuery" inline label-width="75px">
        <el-form-item label="区域:">
          <!-- <div class="select-cordinate" :class="!!chartQuery.data.filed ? 'select-checked' : 'select-cordinate-placeholder'" @click="handleSelectCity()">
            <span v-for="(item, i) in chartQuery.filed" :key="i">{{ item.label }}</span>
            <span v-if="!chartQuery.data.filed || !chartQuery.data.filed.length">全部</span>
          </div> -->
          <el-row :gutter="12">
            <el-col :span="8">
              <el-select v-model="provinceId" placeholder="省" @change="mirProvince" filterable clearable>
                <el-option v-for="(item, i) in provinceList" :key="i" :label="item.districtName" :value="item.districtId"></el-option>
              </el-select>
            </el-col>
            <el-col :span="8">
              <el-select v-model="cityId" placeholder="市" @change="mirCity" filterable clearable>
                <el-option v-for="(item, i) in cityList" :key="i" :label="item.districtName" :value="item.districtId"></el-option>
              </el-select>
            </el-col>
            <el-col :span="8">
              <el-select v-model="districtId" placeholder="区" @change="mirArea" filterable clearable>
                <el-option v-for="(item, i) in areaList" :key="i" :label="item.districtName" :value="item.districtId"></el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="供应商:">
          <el-select v-model="storeId" placeholder="请选择供应商" filterable @change="mirStore" clearable>
            <el-option v-for="(item, i) in storeList" :key="i" :label="item.storeName" :value="item.storeId"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="flex chart-container">
        <div ref="environmentL"></div>
        <div ref="environmentR"></div>
      </div>
    </div>
    <SelectCityPopups :showState="isSelectCity" v-model="chartQuery.filed" v-on:hidePopups="isSelectCity = false" />
  </div>
</template>

<script>
import * as echarts from 'echarts';
import SelectCityPopups from './components/selectCity';
import { getOverallSituation, getCitys, getStoreList } from '@/api/evaluate';

export default {
  components: {
    SelectCityPopups,
  },
  data() {
    return {
      isboxLoading: false,
      timeList: ['今日', '本周', '本月', '本季度', '自定义'],
      timeIndex: 0,
      dateTime: [],
      isTimeLimit: false,
      provinceList: [],
      cityList: [],
      areaList: [],
      storeList: [],
      provinceId: undefined,
      cityId: undefined,
      districtId: undefined,
      storeId: undefined,
      chartQuery: {
        data: {
          createTimeStart: undefined,
          createTimeEnd: undefined,
          storeIds: [],
        },
      },
      environmentLDom: null,
      environmentRDom: null,
      isSelectCity: false,
    };
  },
  mounted() {
    this.chartQuery.data.createTimeStart = this.$commonFn.getDateInterval(0)[0];
    this.chartQuery.data.createTimeEnd = this.$commonFn.getDateInterval(0)[1];
    this.getData();
  },
  methods: {
    async getData() {
      this.getOverallSituation();
      this.provinceList = (await getCitys({ data: { districtType: 1 } })).data || [];
      this.getStoreList(this.provinceId, this.cityId, this.districtId);
    },
    async getStoreList(provinceId, cityId, districtId) {
      this.storeList = (await getStoreList({ data: { provinceId: provinceId, cityId: cityId, districtId: districtId } })).data || [];
    },
    getOverallSituation() {
      try {
        this.isboxLoading = true;
        getOverallSituation(this.chartQuery).then((response) => {
          this.initCharts(response.data);
        });
      } catch {
        this.isboxLoading = false;
      }
    },

    async mirProvince(value) {
      this.cityId = undefined;
      this.districtId = undefined;
      await this.getStoreList(value, this.cityId, this.districtId);
      this.cityList = (await getCitys({ data: { districtId: value, districtType: 2, status: 1 } })).data || [];
      this.chartQuery.data.storeIds = this.storeList.map((ma) => ma.storeId);
      this.storeId = '';
      this.getOverallSituation();
    },
    async mirCity(value) {
      this.districtId = undefined;
      await this.getStoreList(this.provinceId, value, this.districtId);
      this.areaList = (await getCitys({ data: { districtId: value, districtType: 3 } })).data || [];
      this.chartQuery.data.storeIds = this.storeList.map((ma) => ma.storeId);
      this.storeId = '';
      this.getOverallSituation();
    },
    async mirArea(value) {
      await this.getStoreList(this.provinceId, this.cityId, value);
      this.chartQuery.data.storeIds = this.storeList.map((ma) => ma.storeId);
      this.storeId = '';
      this.getOverallSituation();
    },
    mirStore(value) {
      this.chartQuery.data.storeIds = [value];
      this.getOverallSituation();
    },
    initCharts(data) {
      if (!this.environmentLDom) {
        this.environmentLDom = echarts.init(this.$refs.environmentL);
      }
      if (!this.environmentRDom) {
        this.environmentRDom = echarts.init(this.$refs.environmentR);
      }
      let environmentLDom = this.environmentLDom;
      let environmentRDom = this.environmentRDom;

      const environmentIndicator = data.environmentList.map((ma, index) => {
        let obj = {
          name: ma.scoreItemName,
          max: 100,
        };
        if (index === 0) {
          obj['axisLabel'] = { show: true, showMinLabel: true, color: '#000' };
        }
        return obj;
      });

      const environmentValue = data.environmentList.map((ma, index) => Number(ma.avgScore));

      const serviceIndicator = data.serviceList.map((ma, index) => {
        let obj = {
          name: ma.scoreItemName,
          max: 100,
        };
        if (index === 0) {
          obj['axisLabel'] = { show: true, showMinLabel: true, color: '#000' };
        }
        return obj;
      });

      const serviceValue = data.serviceList.map((ma, index) => Number(ma.avgScore));

      let option = {
        title: {
          text: `环境整体评分:${data.environmentScore}`,
          bottom: 0,
          left: '43%',
        },
        tooltip: {
          trigger: 'item',
        },
        radar: {
          indicator: environmentIndicator,
          // [
          //   { name: '拍摄环境', max: 100, axisLabel: { show: true, showMinLabel: true, color: '#000' } },
          //   { name: '道具', max: 100 },
          //   { name: '灯光', max: 100 },
          //   { name: '布景', max: 100 }
          // ]
        },
        series: [
          {
            name: 'Budget vs spending',
            type: 'radar',
            lineStyle: {
              color: '#00A199',
            },
            data: [
              {
                value: environmentValue,
                name: '环境整体评分',
                areaStyle: {
                  color: 'rgba(0, 161, 153, 0.16)',
                },
                itemStyle: {
                  color: '#00A199',
                },
              },
            ],
          },
        ],
      };
      let option1 = {
        title: {
          text: `服务整体评分:${data.serviceScore}`,
          bottom: 0,
          left: '43%',
        },
        tooltip: {
          trigger: 'item',
        },
        radar: {
          indicator: serviceIndicator,
          // [
          //   { name: '服务顾问', max: 100, axisLabel: { show: true, textStyle: { fontSize: 12, color: '#000' } } },
          //   { name: '销售顾问', max: 100 },
          //   { name: '躲闪', max: 100 },
          //   { name: '攻击', max: 100 },
          //   { name: '摄影师', max: 100 }
          // ]
        },
        series: [
          {
            name: 'Budget vs spending',
            type: 'radar',
            lineStyle: {
              color: '#E86247',
            },
            data: [
              {
                value: serviceValue,
                name: '服务整体评分',
                areaStyle: {
                  color: 'rgba(232, 98, 71, 0.16)',
                },
                itemStyle: {
                  color: '#E86247',
                },
              },
            ],
          },
        ],
      };
      option && environmentLDom.setOption(option);
      option1 && environmentRDom.setOption(option1);
      this.isboxLoading = false;
    },
    handleTime(item, index) {
      this.timeIndex = index;
      item === '自定义' ? (this.isTimeLimit = true) : (this.isTimeLimit = false);
      if (index === 4) {
        return;
      }
      this.chartQuery.data.createTimeStart = this.$commonFn.getDateInterval(index)[0];
      this.chartQuery.data.createTimeEnd = this.$commonFn.getDateInterval(index)[1];
      this.getData();
    },
    mirDate(dates) {
      if (dates) {
        this.chartQuery.data.createTimeStart = dates[0];
        this.chartQuery.data.createTimeEnd = dates[1];
      } else {
        this.chartQuery.data.createTimeStart = undefined;
        this.chartQuery.data.createTimeEnd = undefined;
      }
      this.getData();
    },
    handleSelectCity() {
      this.isSelectCity = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.overall-situation-container {
  padding: 24px 20px;
  background-color: #fff;
  .el-select {
    width: 200px;
  }
  .select-cordinate {
    width: 170px;
    min-height: 30px;
    padding: 0 16px;
    border: 1px solid #dcdfe6;
    color: #606266;
    border-radius: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &::after {
      position: absolute;
      top: 0;
      right: 8px;
      content: '';
      font-family: 'element-icons' !important;
    }
    &:hover {
      cursor: pointer;
      border-color: #c0c4cc;
    }
    span {
      margin-right: 8px;
      font-size: 13px;
    }
  }
  .select-checked {
    color: #606266;
  }
  .select-cordinate-placeholder {
    color: #cfcbcb;
  }
  .operate-container {
    align-items: center;
    .operate-item {
      margin-right: 10px;
      padding: 7px 10px;
      border-radius: 4px;
      border: 1px solid #e0e0e0;
      color: #000000;
      font-size: 14px;
    }
    .operate-item:hover {
      cursor: pointer;
      background-color: #2861e2;
      border: 1px solid #2861e2;
      color: #fff;
    }
    .operate-item-checked {
      background-color: #2861e2;
      border: 1px solid #2861e2;
      color: #fff;
    }
  }
  .chart-container {
    margin-top: 80px;
    div {
      width: 50%;
      height: 500px;
    }
  }
}
</style>
